.section
  background-color: #f9f9f9
  background-size: cover
  display: block
  padding: 2em 0

.sectionRose
  background-image: url('~assets/images/backgrounds/background-1@0,25x.jpg')
  background-position: center center

@media all and (min-width: 850px)
  .sectionRose
    background-image: url('~assets/images/backgrounds/background-1@0,5x.jpg')

@media all and (min-width: 1700px)
  .sectionRose
    background-image: url('~assets/images/backgrounds/background-1.jpg')

.sectionVintage
  background-image: url('~assets/images/backgrounds/vintage-pattern.jpg')
  background-size: auto
  background-repeat: repeat

.sectionCoach
  background-image: url('~assets/images/backgrounds/background-coach@0,25x.jpg')
  background-position: 40% 50%

@media all and (min-width: 550px)
  .sectionCoach
    background-image: url('~assets/images/backgrounds/background-coach@0,5x.jpg')

@media all and (min-width: 1000px)
  .sectionCoach
    background-image: url('~assets/images/backgrounds/background-coach.jpg')
